import React, { useState, useEffect, useRef } from "react";
import api from "./api";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme } from "styled-components";
import utils from "./utils";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
  useSearchParams,
} from "react-router-dom";

// Utility functions
const validateUrl = (url) => /^(ftp|http|https):\/\/[^ "]+$/.test(url);
const stripUrl = (url) =>
  url.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, "");

const PageWrapper = styled.section`
  padding: 50px 20px;
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;

  .submit-status-icon {
    position: absolute;
    width: 15px; /* Set the size of the spinner */
    height: 15px; /* Set the size of the spinner */
    top: 10px;
    right: -25px;

    &.loading-spinner {
      animation: spin 1s linear infinite;
    }

    &.icon-success {
      background: #fff;
      border-radius: 50%;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    margin-top: 20px;
    padding: 50px 20px;
  }

  h1 {
    margin: 0;
    font-size: 35px;
    color: ${({ theme }) => theme.textColor};
  }
  h2 {
    margin: 0;
    font-size: 25px;
    color: ${({ theme }) => theme.textColor};
  }
  h3 {
    color: ${({ theme }) => theme.textColor};
    font-size: 25px;
  }
  p {
    color: ${({ theme }) => theme.textColor};
  }
  a {
    color: ${(props) => props.theme.blue};
  }
  .page-description {
    width: 900px;
    max-width: 100%;
  }

  .post-listing {
    width: 100%;
    min-height: 700px;

    .feed-reset {
      color: ${({ theme }) => theme.blue};
      cursor: pointer;
    }

    .wrap {
      height: 85vh;
      overflow: auto;

      @media only screen and (max-width: 1200px) {
        height: auto;
      }
      /* display: grid;
      grid-template-columns: 1fr; */
    }
  }

  .hover-cover {
    opacity: 0;
    position: absolute;
    background: #00000094;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    p {
      font-size: 18px;
      border: 2px solid #fff;
      padding: 5px 30px;
      border-radius: 3px;
      color: #fff !important;
    }
  }

  .content-wrap {
    display: grid;
    grid-template-columns: minmax(500px, 1fr) minmax(400px, 500px);
    column-gap: 20px;
    justify-content: space-between;
    width: 1200px;
    max-width: 100%;
    margin-top: 70px;

    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  .social-post {
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.backgroundColor};
    max-width: 600px;
    padding: 30px 30px 30px 0;
    color: ${(props) => props.theme.textColor};
    border-radius: 5px;
    margin: 0;
    transition: transform 0.2s ease;

    @media only screen and (max-width: 1200px) {
      max-width: 100%;
    }

    @media only screen and (max-width: 1300px) {
      margin: 50px 0;
      width: 100%;
      box-sizing: border-box;
    }

    & > div {
      display: flex;
    }

    .profile-image {
      background-color: black;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @media only screen and (max-width: 600px) {
        display: none;
      }

      img {
        width: 20px;

        @media only screen and (max-width: 600px) {
          /* width: 25px; */
        }
      }
    }
    .post-content {
      margin-left: 0;
      width: 518px;
      max-width: 100%;
      /* border-left: 2px solid ${(props) => props.theme.divider}; */
      padding-left: 20px;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }

      p {
        margin: 0;
        color: ${(props) => props.theme.textColor};
      }
      .post-header {
        display: flex;
        justify-content: space-between;
        position: relative;

        .share-banner {
          opacity: 0;
          position: absolute;
          top: -30px;
          right: 0;
          width: fit-content;
          text-align: center;
          z-index: 2;
          transition: opacity 150ms;
          border-radius: 2px;

          p {
            font-size: 14px;
            color: ${(props) => props.theme.blue};
          }

          &.copied {
            opacity: 1;
          }
        }

        & > div:nth-child(2) {
          display: flex;
          align-content: center;
          position: relative;

          @media only screen and (max-width: 600px) {
            max-width: 100%;
            white-space: nowrap;
          }
        }

        h4 {
          margin: 0;
          @media only screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
        /* dot */
        span {
          top: -8px;
          font-size: 21px;
          position: relative;
        }

        p {
          margin: 0 5px;
          margin-top: 2px;
          @media only screen and (max-width: 600px) {
            font-size: 14px;
            margin-top: 2px;
          }
        }

        .copy-hover {
          display: none;
          color: ${(props) => props.theme.blue};
          font-size: 12px;
          position: absolute;
          top: -20px;
          right: -20px;

          &.hide {
            display: none !important;
          }
        }

        .copy-wrap {
          width: 150px;
          text-align: right;

          @media only screen and (max-width: 600px) {
            width: 60px;
          }
          img {
            width: 20px;
            margin-right: 0;
            margin-left: auto;
            opacity: 0.5;
            transition: opacity 0.2s ease;
          }
          &:hover {
            opacity: 1;
            cursor: pointer;

            .copy-hover {
              display: block;
            }
          }
        }
      }

      .post-text {
        font-size: 18px;
        margin-bottom: 10px;
        color: ${(props) => props.theme.textColor};
        position: relative;

        span {
          font-weight: 700;
          text-transform: uppercase;
        }

        @media only screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      .image-wrap {
        margin-top: 20px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        width: 100%; /* Make the container responsive */
        max-width: 1200px; /* Limit the maximum width */
        height: 0; /* Height is calculated based on padding-bottom */
        padding-bottom: 50%; /* Aspect ratio of 1200x600 (600/1200 * 100%) */
        position: relative; /* Position the image */
        overflow: hidden; /* Hide overflowing content */

        @media only screen and (max-width: 600px) {
          /* width: 100%;
          height: 150px; */
        }

        .post-image {
          position: absolute; /* Position the image absolutely within the container */
          width: 100%; /* Ensure the image fills the container */
          height: 100%; /* Ensure the image fills the container */
          object-fit: cover; /* Maintain aspect ratio while covering the container */
        }
      }
    }

    .post-url {
      margin-top: 10px !important;
      width: fit-content;
      font-weight: 500;
      font-size: 18px;

      /* img {
        width: 15px;
        position: relative;
        top: 2px;
        margin-right: 10px;
        pointer-events: none;
      } */
    }

    .share-buttons {
      text-align: right;
      margin-right: 30px;
      margin-left: auto;
      margin-top: 20px;

      p {
        display: block;
        margin: 0 10px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
          color: ${(props) => props.theme.blue};
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  #leaderboard {
    padding: 20px;
    background: ${(props) => props.theme.backgroundColor};
    position: sticky;
    top: 0;

    h3 {
      margin: 50px 0 0 0;
      font-size: 21px;
    }

    button {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      padding: 0 42px;
      border: none;
      border-radius: 100px;
      background-color: #0075ff;
      color: white;
      margin: 30px 0 0 0;
      cursor: pointer;
      position: relative;
    }

    form {
      margin-top: 20px;
      position: relative;

      .submitted-text {
        color: ${(props) => props.theme.textColor};
        font-weight: 500;
        font-size: 14px;
        margin-left: 10px;

        &.error {
          color: red;
        }
      }
    }

    ol {
      text-align: left;
      list-style: none;
      padding: 0;
      margin: 0 auto;

      li {
        font-size: 18px;
        padding: 20px 0;
        border-bottom: 1px solid ${(props) => props.theme.divider};
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 85%;
        color: ${(props) => props.theme.textColor};

        @media only screen and (max-width: 800px) {
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          margin-left: 40px;
          position: relative;
        }

        .email {
          min-width: 250px;
          text-align: left;
        }
        .num {
          width: 25px;
          font-size: 30px;
          margin-right: 10px;
          @media only screen and (max-width: 800px) {
            position: absolute;
            left: -35px;
            font-size: 40px;
          }
        }
        .points {
          @media only screen and (max-width: 800px) {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
`;

const SubmitLink = styled.input`
  background: ${({ theme }) => theme.hoverBackground};
  border-radius: 25px;
  outline: none;
  border: none;
  height: 30px;
  color: ${({ theme }) => theme.textColor};
  padding-left: 20px;
  position: sticky;
  top: 10px;
  width: 425px;
  max-width: 100%;
  cursor: pointer;

  @media only screen and (max-width: 800px) {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
`;

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).catch((error) => {
    console.error("Error copying text to clipboard:", error);
  });
};

function updateUser(data, userId, oktaAuth) {
  let userData = api.updateItem("social_leaderboard", userId, oktaAuth, data);
  if (userData == "success") {
    return;
  }
}
function createUser(data, oktaAuth) {
  let userData = api.createItem("social_leaderboard", oktaAuth, data, "");
  if (userData == "success") {
    return;
  }
}
async function checkForUser(email, oktaAuth) {
  const filterParams = `&filter[_and][0][_and][0][email][_eq]=${email}`;
  try {
    const userData = await api.collection(
      "social_leaderboard",
      oktaAuth,
      filterParams
    );
    return userData.length === 1 ? userData[0] : null;
  } catch (error) {
    console.error("Failed to retrieve user:", error);
    return null;
  }
}

const validateSocialMediaPostURL = (url) => {
  const patterns = {
    facebook: /https?:\/\/(www\.)?facebook\.com\/.+/i,
    linkedin: /https?:\/\/(www\.)?linkedin\.com\/(in\/|feed\/|posts\/).+/i,
    twitter: /https?:\/\/(www\.)?twitter\.com\/.+/i,
    otherX: /https?:\/\/exampleX\.com\/.+/i, // Replace exampleX.com with the actual domain
  };

  // Check if the URL matches any of the patterns
  return Object.values(patterns).some((regex) => regex.test(url));
};

const submitForPoints = async (user, userName, userEmail, link, oktaAuth) => {
  // Is the user in Directus already?
  // const validPost = await validateSocialMediaPostURL(link);
  const validPost = true;
  if (user && user.shared_links) {
    // Has this link been counted already?
    if (user.shared_links.some((l) => stripUrl(l.link) === stripUrl(link))) {
      console.log({ error: "This link has already been submitted" });
      return { error: "This link has already been submitted" };
    } else {
      let pageData = await api.getWebsiteMetadata(link);

      if (!pageData) {
        console.log("bad link");
      }

      user.shared_links.push({ link });
      await updateUser({ shared_links: user.shared_links }, user.id, oktaAuth);
    }
  } else if (user && !user.shared_links) {
    if (validPost) {
      user.shared_links = [{ link }];
      await updateUser({ shared_links: user.shared_links }, user.id, oktaAuth);
    } else {
      return {
        error: "Link needs to be a valid Linked, Facebook, or X (Twitter) link",
      };
    }
  } else {
    await createUser(
      {
        email: userEmail,
        shared_links: [{ link }],
        name: userName,
        status: "published",
      },
      oktaAuth
    );
  }
  return { success: true };
};

const SocialPost = ({ directusData, userEmail, oktaAuth }) => {
  const [siteData, setSiteData] = useState(directusData);
  const [siteImage, setSiteImage] = useState("");
  const [copiedText, setCopiedText] = useState(false);

  const copyPostTextAndSubmitLink = async (text) => {
    copyToClipboard(text);

    // Check for user
    // Check if the link exists

    const user = await checkForUser(userEmail, oktaAuth);
    // const result = await submitForPoints(
    //   user,
    //   userEmail,
    //   siteData.post_url,
    //   oktaAuth
    // );
    setCopiedText(true);
    setTimeout(() => {
      setCopiedText(false);
    }, 3000);
  };

  useEffect(() => {
    const getSiteData = async (siteUrl) => {
      try {
        let siteMetaData = await api.getWebsiteMetadata(siteUrl);
        let postImage = siteMetaData.metadata.ogImage;
        setSiteImage(postImage);
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };

    // Fetch site data and set state
    getSiteData(siteData.post_url);
  }, []);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const today = `${months[new Date().getMonth()]} ${new Date().getDate()}`;

  if (!siteData) {
    return <></>;
  }
  return (
    <>
      <div className="social-post platform-x">
        <div>
          <div className="profile-image">
            <img src="https://assets.mx.com/images/mx-logo.svg" />
          </div>
          <div className="post-content">
            <div className="post-header">
              <div className={`share-banner ${copiedText ? "copied" : ""}`}>
                <p>Post Copied to Clipboard</p>
              </div>
              <div>
                <h4>MX Technologies, Inc.</h4>
                <p>@mx</p> <span>.</span>
                <p>
                  {utils.easyToReadDate(siteData.date_created.split("T")[0])}
                </p>
              </div>
              <div className="copy-wrap">
                <p className={`copy-hover ${copiedText ? "hide" : ""}`}>
                  Copy post to clipboard
                </p>
                <img
                  onClick={() => {
                    const textToCopy = `${siteData.share_text}\n\n${siteData.post_url}`;
                    copyPostTextAndSubmitLink(textToCopy);
                  }}
                  src="https://assets.mx.com/hub/images/share/icon-copy.svg"
                />
              </div>
            </div>
            <div className="post-text">
              <p>{siteData.share_text}</p>
            </div>
            <p className="post-url">{siteData.post_url}</p>
            <div className="image-wrap">
              <img className="post-image" src={siteImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function SocialTool({ theme }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const [thePostFeed, setThePostFeed] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [feedback, setFeedback] = useState({ message: "", type: "" });
  const [submitStatus, setSubmitStatus] = useState("");
  const [individualPost, setIndividualPost] = useState(null);

  const history = useHistory();
  const location = useLocation();

  // Custom hook to get query parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const handleSubmit = async (event) => {
    setSubmitStatus("submitting");
    event.preventDefault();
    // Check for valid URL

    // if (!validateUrl(inputValue)) {
    //   setSubmitStatus("");
    //   setFeedback({ message: "Invalid URL", type: "error" });
    //   return;
    // }

    // Check for user
    // Check if the link exists

    const user = await checkForUser(userEmail, oktaAuth);

    const result = await submitForPoints(
      user,
      oktaInfo.name,
      userEmail,
      inputValue,
      oktaAuth
    );

    if (result.error) {
      setSubmitStatus("");
      setFeedback({ message: result.error, type: "error" });
    } else {
      setSubmitStatus("submitted");
      setFeedback({
        message: "Link Submitted Successfully",
        type: "success",
      });
      setInputValue("");
    }

    setTimeout(() => {
      setSubmitStatus("");
      setFeedback({ message: "", type: "" });
    }, 3000);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const resetFeed = () => {
    setIndividualPost(null);
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      const userEmail = authState.idToken.claims.email;
      const userName = authState.idToken.claims.name;

      setUserEmail(userEmail);
      setUserName(userName);
      setOktaInfo(authState.idToken.claims);
    }
  }, [oktaInfo]);

  // Get Social Feed and Leader Board
  useEffect(() => {
    const getPostFeed = async () => {
      let filterParams = "filter[status][_eq]=published";
      filterParams += "&sort=-date_created";
      let feedData = await api.collection(
        "social_sharing",
        oktaAuth,
        filterParams
      );

      setThePostFeed(feedData);
    };
    const getLeaderBoard = async (limit) => {
      let filterParams = `limit=${limit}`;
      let leaderBoardData = await api.collection(
        "social_leaderboard",
        oktaAuth,
        filterParams
      );

      if (leaderBoardData) {
        // First, filter out entries without any shared_links
        const filteredData = leaderBoardData.filter(
          (item) => item.shared_links && item.shared_links.length > 0
        );

        // Then, sort the remaining entries
        filteredData.sort(
          (a, b) => b.shared_links.length - a.shared_links.length
        );

        setLeaderBoard(filteredData);
      }
    };
    getPostFeed();
    getLeaderBoard(5);
  }, []);

  const query = useQuery();
  const postId = query.get("post");

  useEffect(() => {
    // If postId exists in the URL, set the individual post
    if (postId && thePostFeed.length > 0) {
      const post = thePostFeed.find((p) => p.id === parseInt(postId, 10));
      setIndividualPost(post || null);
    }
  }, [postId, thePostFeed]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get("defaultParam")) {
      params.set("defaultParam", "defaultValue");
      history.replace({ search: params.toString() });
    }
  }, [history, location.search]);

  return (
    <>
      <PageWrapper>
        <h1>Social Resources</h1>
        {/* <p className="page-description">
          Here, you'll find everything you need to amplify MX's message across
          your social networks. From suggested posts to pre-designed banners and
          cover images, this page is your one-stop destination for sharing our
          story effectively.
        </p> */}

        <div className="content-wrap">
          <div className="post-listing">
            <h2>Suggested {individualPost ? "Post" : "Posts"}</h2>
            {individualPost ? (
              <div className="wrap">
                <SocialPost
                  key={individualPost.id}
                  directusData={individualPost}
                  oktaAuth={oktaAuth}
                  userEmail={userEmail}
                  userName={userName}
                />
                <p className="feed-reset" onClick={() => resetFeed()}>
                  See all posts
                </p>
              </div>
            ) : (
              <div className="wrap">
                {thePostFeed &&
                  thePostFeed.map((post) => {
                    return (
                      <SocialPost
                        // hash={hash}
                        key={post.id}
                        directusData={post}
                        oktaAuth={oktaAuth}
                        userEmail={userEmail}
                        userName={userName}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          <div id="leaderboard">
            {!individualPost && (
              <>
                <h2>Share Leaderboard</h2>
                {leaderBoard && (
                  <ol>
                    {leaderBoard.map((leader, index) => {
                      let numberOfPoints = leader.shared_links
                        ? leader.shared_links.length
                        : 0;
                      return (
                        <li key={index}>
                          <span className="num">{index + 1}</span>
                          <span className="email">{leader.name} </span>
                          <span className="points">{`${numberOfPoints} ${
                            numberOfPoints > 1 ? "points" : "point"
                          }`}</span>
                        </li>
                      );
                    })}
                  </ol>
                )}
              </>
            )}
            <h3>Submit a Link</h3>
            <p>
              Boost your score by sharing our provided posts on your socials or
              by sharing your own MX related content! Simply submit the links to
              your active shared content here to earn points.
            </p>
            <form onSubmit={handleSubmit}>
              <SubmitLink
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder="Link to your shared post"
              />
              <button type="submit">
                Submit{" "}
                {submitStatus === "submitting" && (
                  <img
                    className="submit-status-icon button loading-spinner"
                    src="https://assets.mx.com/hub/images/share/icon-spinner.svg"
                  />
                )}
                {submitStatus === "submitted" && (
                  <img
                    className="submit-status-icon icon-success"
                    src="https://assets.mx.com/hub/images/share/icon-check.svg"
                  />
                )}
              </button>

              {feedback && (
                <p className={`submitted-text ${feedback.type}`}>
                  {feedback.message}
                </p>
              )}
            </form>
          </div>
        </div>
      </PageWrapper>
    </>
  );
}

export default withTheme(SocialTool);
