import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import api from "./api";
import { useOktaAuth } from "@okta/okta-react";
import parse from "html-react-parser";

// #64a338
// #ffcc00
// #e03b24
// #3865a3
// #87a2c7

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1650px;
  margin: 0 auto;
  justify-content: center;
  padding: 0 20px 250px 50px;

  @media only screen and (max-width: 1350px) {
    max-width: 80%;
    margin-left: 0;
    padding: 0 10px 100px 30px;
  }

  @media only screen and (max-width: 500px) {
    margin: 0;
  }

  .admin-btn {
    color: ${({ theme }) => theme.textColor};
    font-weight: 600;
    float: right;
    margin-right: 50px;

    .status {
      &.on {
        color: #64a338;
      }
      &.off {
        color: #e03b24;
      }
    }
  }

  .no-questions {
    font-size: 21px;
    margin: 0;
    padding-bottom: 30px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 500;
    padding-left: 30px;
    opacity: 0.5;
  }

  h1 {
    max-width: 1052px;
    color: ${({ theme }) => theme.textColor};
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.2;

    @media only screen and (max-width: 500px) {
      font-size: 35px;
    }
  }

  h2 {
    margin-bottom: 30px;
    font-size: 21px;
    color: ${({ theme }) => theme.textColor};
  }

  p {
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
  }

  .error {
    color: #e03b24;
    font-weight: 600;
  }
  .success {
    color: #64a338;
    font-weight: 600;
  }

  .number-bubble {
    background: ${({ theme }) => theme.blue};
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
`;

const FullWidthContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};

  * {
    font-family: Proxima-nova;
  }
`;

const Hero = styled.div`
  width: 100%;
  padding: 50px 0 20px 0;

  p {
    width: 900px;
    max-width: 100%;
  }

  textarea {
    width: 95% !important;
  }
`;

const Questions = styled.div`
  width: 950px;
  max-width: 100%;
  margin-top: 50px;

  .pending-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;
    cursor: pointer;

    .arrow {
      margin-left: 10px;
      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .pending-questions {
    border-bottom: 1px solid ${({ theme }) => theme.blue};

    &.closed {
      display: none;
    }
  }

  #answered-questions {
    margin-top: 50px;
    width: 1000px;
    max-width: 100%;

    &:before {
      content: "";
      height: 2px;
      width: 80%;
    }
  }

  .question {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    width: 900px;
    flex: 1;
    position: relative;
    padding-bottom: 30px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &.question-answered {
      margin-left: 60px;

      @media only screen and (max-width: 800px) {
        margin-left: 20px;
      }

      @media only screen and (max-width: 500px) {
        margin-left: 0;
      }

      .upvote {
        display: none;
      }
    }

    .question-wrap {
      width: 100%;
    }

    .question-text {
      margin: 5px 0;
      font-style: italic;

      .asker {
        font-style: normal;
        font-weight: 700;
      }
    }

    .toolbar {
      display: flex;
      align-items: center;

      #directus-link {
        cursor: pointer;
        margin-left: 10px;
        display: inline-block;

        .link-icon {
          width: 15px;
          height: 15px;
        }
      }

      .answered-icon {
        width: 20px;
        height: 20px;
        margin-top: -2px;
        margin-right: 15px;

        &.users-question {
          cursor: pointer;
        }

        &.admin-hover {
          filter: none;
        }

        &.unanswered {
          margin: 0;
          margin-left: 15px;
          filter: grayscale(1);

          &:hover {
            filter: none;
          }
        }

        &.hide {
          display: none;
        }
      }

      .comments-indicator {
        position: relative;
        width: fit-content;
        cursor: pointer;
        display: flex;
        margin: 0 10px 0 0;

        p {
          /* position: absolute; */
          margin-left: 5px;
          font-weight: 700;
          font-size: 14px;
          color: ${({ theme }) => theme.blue};
        }
      }
    }

    .answer-toggle {
      color: ${({ theme }) => theme.blue};
      font-size: 16px;
      cursor: pointer;
      margin-bottom: 30px;
    }

    .the-comments {
      margin: 0 0 20px 20px;
      color: ${({ theme }) => theme.textColor};

      &.active {
        display: block;
      }

      .status-dot {
        background: ${({ theme }) => theme.divider};
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
        cursor: pointer;

        &.approved {
          background: #64a338;
        }
        &.review {
          background: #ffcc00;
        }
      }

      .comment-text {
        padding: 0 20px 5px 20px;
        margin: 20px 0;
        border-left: 2px solid ${({ theme }) => theme.sectionColor};

        &:first-of-type {
          margin-top: 0;
        }

        &.show-comment {
          display: block;
        }
      }

      h3 {
        margin: 0;
        padding: 0 0 10px 0;
        font-size: 16px;
        width: fit-content;
        color: ${({ theme }) => theme.textColor};
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.8;
        margin: 10px 0;
        color: ${({ theme }) => theme.textColor};

        b {
          font-weight: 700;
        }
        strong {
          font-weight: 700;
        }

        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .leave-a-comment {
      margin-top: 20px;
      border-top: 2px solid ${({ theme }) => theme.sectionColor};

      h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.textColor};
      }
    }

    button {
      width: fit-content;
      padding: 5px 40px;
    }

    textarea {
      color: #262626;
      padding: 10px;
      border: 1px solid #262626;
      border-radius: 10px;
      width: 100%;
      min-height: 40px;
      margin-right: 10px;
    }
  }
`;

const Votes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .num {
    margin: 0;
  }
  min-width: 40px;
  min-height: 40px;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;

  p {
    font-weight: 600;
  }

  .arrow-wrap {
    color: ${({ theme }) => theme.red};

    p {
      color: ${({ theme }) => theme.red};
      font-weight: 600;
    }

    i {
    }

    .arrow {
      transition: all 0.1s ease;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);

        &.down-arrow {
          transform: scale(1.1) rotate(180deg);
        }
      }
    }
    .up-arrow {
    }
    .down-arrow {
      transform: rotate(180deg);
    }

    &.disabled {
    }
  }

  .up-arrow {
  }
  .down-arrow {
  }

  .num {
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};
    margin: 0;
  }
`;

const SubmitButton = styled.button`
  text-align: center;
  background: none;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 10px;
  max-width: 200px;
  width: 100%;
  cursor: pointer;
  background: #2f73da;
  border-radius: 20px;
  color: white;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  &.disabled {
    background: gray !important;
    pointer-events: none;
  }
`;

const SubmitWrapper = styled.div`
  background: ${({ theme }) => theme.hoverBackground};
  padding: 20px;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 900px;
  max-width: 100%;

  @media only screen and (max-width: 700px) {
    width: 90%;
  }

  h2 {
    margin: 0;
    font-size: 18px;
  }

  textarea {
    margin-top: 15px;
    background: none;
    color: ${({ theme }) => theme.textColor};
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.textColor};
    border-radius: 10px;
    width: 100%;
    min-height: 80px;

    ::placeholder {
      color: ${({ theme }) => theme.textColor};
    }
  }
`;

const AccordionWrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.divider};

  .accordion-header {
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    width: 100%;

    h3 {
    }

    .arrow {
      margin-left: 10px;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    padding: 15px;
    display: none;
    transition: max-height 0.2s ease;

    &.opened {
      display: block;
    }
  }
`;

function formatDate(isoString) {
  const date = new Date(isoString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

function AskPage({ theme }) {
  const suggestionRef = useRef("");
  const { authState, oktaAuth } = useOktaAuth();
  const [questions, setQuestions] = useState([]);
  const [submitted, setSubmitted] = useState(true);
  const [message, setMessage] = useState(null);
  const [adminMode, setAdminMode] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [closePending, setClosePending] = useState(false);
  const [pendingQuestions, setPendingQuestions] = useState([]);

  const oktaInfoString = localStorage.getItem("okta-token-storage");
  const userInfo = JSON.parse(oktaInfoString).idToken.claims;
  const userEmail = userInfo.email;
  const userName = userInfo.name;

  const isTom = userEmail === "tom.kittell@mx.com";
  // const isTom = userEmail === "cameron.bullock@mx.com";

  const accordionArrow =
    theme.name == "light"
      ? "https://assets.mx.com/hub/images/q-a/arrow-black.svg"
      : "https://assets.mx.com/hub/images/q-a/arrow.svg";

  const sendQuestionsData = async (data, id) => {
    const questionData = await api.updateItem(
      "townhall_questions",
      id,
      oktaAuth,
      data
    );
    if (questionData === "success") {
      console.log("success");
    }
  };

  const toggleAdmin = () => {
    setAdminMode((prevState) => !prevState);
  };

  const togglePendingCollapse = () => {
    setClosePending((prevState) => !prevState);
  };

  const removeVoteByEmail = (upvotesArray, email) => {
    return upvotesArray.filter((upvote) => upvote.employee_email !== email);
  };

  useEffect(() => {
    const adminList = ["ali.kirk@mx.com", "cameron.bullock@mx.com"];

    if (adminList.includes(userEmail)) {
      setIsAdmin(true);
    }

    const fetchData = async () => {
      const filterParams = "filter[status][_eq]=published&limit=-1";
      const questionsData = await api.collection(
        "townhall_questions",
        oktaAuth,
        filterParams
      );

      const normalizedQuestions = questionsData.map((question) => ({
        ...question,
        answered: question.answered || false,
        upvotes: question.upvotes || [],
        downvotes: question.downvotes || [],
        comments: question.comments || [],
        approvedComments: (question.comments || []).filter(
          (comment) => comment.status === "approved"
        ),
        totalVotes:
          (question.upvotes?.length || 0) - (question.downvotes?.length || 0),
        isUpvoted: (question.upvotes || []).some(
          (vote) => vote.employee_email === userEmail
        ),
        isDownvoted: (question.downvotes || []).some(
          (vote) => vote.employee_email === userEmail
        ),
      }));

      normalizedQuestions.sort((a, b) => b.totalVotes - a.totalVotes);

      const pendingList = normalizedQuestions.filter(
        (question) => question.answered === false
      );

      setPendingQuestions(pendingList);
      setQuestions(normalizedQuestions);
    };

    fetchData();
  }, [oktaAuth, userEmail]);

  const handleVoteChange = (
    id,
    newUpvotes,
    newDownvotes,
    newAnswerStatus = null
  ) => {
    setPendingQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            upvotes: newUpvotes,
            downvotes: newDownvotes,
            totalVotes: (newUpvotes?.length || 0) - (newDownvotes?.length || 0),
            isUpvoted: (newUpvotes || []).some(
              (vote) => vote.employee_email === userEmail
            ),
            isDownvoted: (newDownvotes || []).some(
              (vote) => vote.employee_email === userEmail
            ),
            answered:
              newAnswerStatus !== null ? newAnswerStatus : question.answered, // Preserve or update the status
          };
        } else {
          return question;
        }
      });

      // Sort questions based on totalVotes in descending order
      updatedQuestions.sort((a, b) => b.totalVotes - a.totalVotes);

      return updatedQuestions;
    });
  };

  const handleSubmit = async () => {
    if (!suggestionRef.current.value) {
      setMessage({
        type: "error",
        message: "Submitted question can't be empty",
      });
      return;
    }
    const data = {
      question: suggestionRef.current.value,
      name: userName,
    };
    try {
      const suggestionPost = await api.createItem(
        "townhall_questions",
        oktaAuth,
        data
      );
      if (suggestionPost === "success") {
        setSubmitted(true);
        setMessage({
          type: "success",
          message:
            "Your question has been submitted for review and will be listed below once approved!",
        });
        setTimeout(() => {
          setSubmitted(false);
          setMessage(null);
        }, 5000);

        suggestionRef.current.value = "";
      } else {
        setMessage({
          type: "error",
          message: "Submission failed. Please try again.",
        });
      }
    } catch (error) {
      const errorMessage = `Error: ${error}`;
      setMessage({ type: "error", message: errorMessage });
    }
  };

  const updateCommentData = (questionId, updatedComments) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.map((question) => {
        if (question.id === questionId) {
          return { ...question, comments: updatedComments };
        }
        return question;
      });

      return updatedQuestions;
    });
  };

  const Question = ({
    questionInfo,
    theme,
    onVoteChange,
    adminMode,
    onApproveComment,
    answeredMode,
  }) => {
    const [showAllComments, setShowAllComments] = useState(false);
    const [comments, setComments] = useState(
      questionInfo.approvedComments || []
    );
    const [commentSubmitText, setCommentSubmitText] = useState("");
    const [commentStatusMessage, setCommentStatusMessage] = useState(null);

    const AnsweredHoverIcon = ({
      handleUnansweredQuestion,
      className = "",
    }) => {
      const [iconSrc, setIconSrc] = useState(
        "https://assets.mx.com/hub/images/q-a/answered-icon.svg"
      );

      const usersQuestion = questionInfo.name === userName;
      const unAnsweredAdminHover = adminMode && !answeredMode;

      const handleMouseEnter = () => {
        if (usersQuestion) {
          setIconSrc(
            "https://assets.mx.com/hub/images/q-a/icon-unanswered.svg"
          );
        }
      };

      const handleMouseLeave = () => {
        if (usersQuestion) {
          setIconSrc("https://assets.mx.com/hub/images/q-a/answered-icon.svg");
        }
      };

      return (
        <img
          src={iconSrc}
          alt="icon"
          className={`${className} ${
            usersQuestion || adminMode ? "users-question" : ""
          } ${unAnsweredAdminHover ? "admin-hover" : ""}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleUnansweredQuestion}
        />
      );
    };
    const voteIcon =
      theme.name == "light"
        ? "https://s3.us-west-2.amazonaws.com/assets.mx.com/hub/images/q-a/icon-vote.svg"
        : "https://assets.mx.com/hub/images/q-a/icon-vote-light.svg";

    const commentIcon =
      theme.name == "light"
        ? "https://assets.mx.com/hub/images/q-a/icon-comments.svg"
        : "https://assets.mx.com/hub/images/q-a/Chat.svg";

    const externalLinkIcon =
      theme.name == "light"
        ? "https://assets.mx.com/hub/images/q-a/external-icon.svg"
        : "https://assets.mx.com/hub/images/q-a/external-icon-light.svg";

    const handleUnansweredQuestion = async () => {
      const questionId = questionInfo.id;
      const updatedUpvotes = questionInfo.upvotes;
      const updatedDownvotes = questionInfo.downvotes;

      if (!questionInfo.answered) {
        if (adminMode) {
          try {
            // Change Local Answer State
            onVoteChange(questionId, updatedUpvotes, updatedDownvotes, true);
            // Update Directus Database
            await sendQuestionsData({ answered: true }, questionInfo.id);
          } catch (error) {
            console.error("Error handling answer status:", error);
          }
        }
        console.log("already marked unanswered");
        return;
      }

      if (questionInfo.name !== userName && !adminMode) {
        return;
      }

      try {
        // Change Local Answer State
        onVoteChange(questionId, updatedUpvotes, updatedDownvotes, false);
        // Update Directus Database
        await sendQuestionsData({ answered: false }, questionInfo.id);
      } catch (error) {
        console.error("Error handling answer status:", error);
      }
    };

    // probably merge theses two handlers
    const handleUpvote = async () => {
      let newUpvotesData = questionInfo.upvotes;
      let newDownvotesData = questionInfo.downvotes;

      // If upvoted already remove upvote
      if (questionInfo.isUpvoted) {
        newUpvotesData = await removeVoteByEmail(
          questionInfo.upvotes,
          userEmail
        );
      } else {
        // If also downvoted, remove downvote
        if (questionInfo.isDownvoted) {
          newDownvotesData = await removeVoteByEmail(
            questionInfo.downvotes,
            userEmail
          );
        }
        // Continue on to add the upvote
        const vote = {
          employee_email: userEmail,
          employee_name: userName,
        };

        newUpvotesData = [...questionInfo.upvotes, vote];
      }
      try {
        // Change Local Upvote State
        onVoteChange(questionInfo.id, newUpvotesData, newDownvotesData);
        // Update Directus Database
        await sendQuestionsData(
          { upvotes: newUpvotesData, downvotes: newDownvotesData },
          questionInfo.id
        );
      } catch (error) {
        console.error("Error handling upvote:", error);
      }
    };

    const handleDownvote = async () => {
      let newUpvotesData = questionInfo.upvotes;
      let newDownvotesData = questionInfo.downvotes;

      // If downvoted already remove downvote
      if (questionInfo.isDownvoted) {
        newDownvotesData = await removeVoteByEmail(
          questionInfo.downvotes,
          userEmail
        );
      } else {
        // If also Upvoted, remove upvote
        if (questionInfo.isUpvoted) {
          newUpvotesData = await removeVoteByEmail(
            questionInfo.upvotes,
            userEmail
          );
        }

        // Continue on to add the downvote
        const vote = {
          employee_email: userEmail,
          employee_name: userName,
        };

        newDownvotesData = [...questionInfo.downvotes, vote];
      }
      try {
        // Change Local State
        onVoteChange(questionInfo.id, newUpvotesData, newDownvotesData);
        // Update Database
        await sendQuestionsData(
          { upvotes: newUpvotesData, downvotes: newDownvotesData },
          questionInfo.id
        );
      } catch (error) {
        console.error("Error handling downvote:", error);
      }
    };

    const approveComment = async (e, commentText, status) => {
      if (status === "approved") {
        return;
      }

      const updatedComments = comments.map((comment) =>
        comment.comment === commentText
          ? { ...comment, status: "approved" }
          : comment
      );

      // set Locally
      setComments(updatedComments);
      // set Globally
      await onApproveComment(questionInfo.id, updatedComments);
      // set in Directus
      await sendQuestionsData({ comments: updatedComments }, questionInfo.id);
    };

    const handleCommentSubmit = async (e) => {
      e.preventDefault();
      if (commentSubmitText.trim() === "") {
        setCommentStatusMessage({
          type: "error",
          message: "Comment can't be empty",
        });
        return;
      }

      try {
        const newComment = {
          status: "review",
          comment: commentSubmitText,
          commenter: userName,
        };

        const updatedComments = [...questionInfo.comments, newComment];
        await sendQuestionsData({ comments: updatedComments }, questionInfo.id);

        setCommentSubmitText("");
        setCommentStatusMessage(null);
        // Optionally call a parent handler to update comments state if needed
        setCommentStatusMessage({
          type: "success",
          message: "Comment submitted for review",
        });
        setTimeout(() => {
          // setCommentStatusMessage(null);
        }, 3000);
      } catch (error) {
        setCommentStatusMessage({
          type: "error",
          message: `Error handling comment: ${error}`,
        });
        console.error("Error handling comment:", error);
      }
    };

    useEffect(() => {
      if (adminMode) {
        setShowAllComments(true);
        setComments(questionInfo.comments);
      }
    }, [adminMode]);

    return (
      <div
        id={"question-" + questionInfo.id}
        className={`question ${questionInfo.answer ? "question-answered" : ""}`}
      >
        {!answeredMode && (
          <Votes>
            <div
              className={`arrow-wrap upvote ${
                questionInfo.isUpvoted ? "upvoted disabled" : ""
              }`}
              onClick={handleUpvote}
            >
              <img
                src={
                  questionInfo.isUpvoted
                    ? "https://assets.mx.com/hub/images/q-a/icon-vote-blue.svg"
                    : voteIcon
                }
                className="up-arrow arrow"
              />
            </div>
            <p className="num">{questionInfo.totalVotes}</p>
            <div
              className={`arrow-wrap downvote ${
                questionInfo.isDownvoted ? "downvoted disabled" : ""
              }`}
              onClick={handleDownvote}
            >
              <img
                src={
                  questionInfo.isDownvoted
                    ? "https://assets.mx.com/hub/images/q-a/icon-vote-blue.svg"
                    : voteIcon
                }
                className="down-arrow arrow"
              />
            </div>
          </Votes>
        )}
        <div className="question-wrap">
          <p className="question-text">
            <span className="asker">
              {questionInfo.name} - {formatDate(questionInfo.date_created)}
            </span>
            {" - "}
            {questionInfo.question}
          </p>

          <div className="toolbar">
            {answeredMode && (
              <AnsweredHoverIcon
                handleUnansweredQuestion={handleUnansweredQuestion}
                className={`answered-icon`}
              />
            )}

            <div
              className="comments-indicator"
              onClick={() => setShowAllComments(!showAllComments)}
            >
              <img src={commentIcon} />
              <p>{comments && comments.length}</p>
            </div>
            {adminMode && (
              <>
                <a
                  id="directus-link"
                  href={`https://directus.hub.mx.com/admin/content/townhall_questions/${questionInfo.id}`}
                  target="_blank"
                >
                  <img src={externalLinkIcon} className="link-icon" />
                </a>
                <AnsweredHoverIcon
                  handleUnansweredQuestion={handleUnansweredQuestion}
                  className={`answered-icon ${
                    answeredMode ? "hide" : "unanswered"
                  }`}
                />
              </>
            )}
          </div>

          {showAllComments && (
            <>
              <div className="the-comments">
                {comments.map((comment, index) => (
                  <div className="comment-text" key={index}>
                    {adminMode && (
                      <div
                        onClick={(e) =>
                          approveComment(e, comment.comment, comment.status)
                        }
                        className={`status-dot ${comment.status}`}
                      />
                    )}
                    <span>
                      <b>{comment.commenter} - </b>
                    </span>
                    {parse(comment.comment)}
                  </div>
                ))}
              </div>
              {!answeredMode && (
                <div className="leave-a-comment">
                  <h3>Leave a Comment</h3>
                  <form onSubmit={handleCommentSubmit}>
                    <textarea
                      placeholder="leave a comment"
                      value={commentSubmitText}
                      onChange={(e) => setCommentSubmitText(e.target.value)}
                    />
                    <SubmitButton className="comment-submit-btn">
                      Submit
                    </SubmitButton>
                    {commentStatusMessage && (
                      <p className={commentStatusMessage.type}>
                        {commentStatusMessage.message}
                      </p>
                    )}
                  </form>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const QuestionAccordion = ({ month, year, data }) => {
    const [accordionOpened, setAccordionOpened] = useState(false);
    const [monthsQuestions, setMonthsQuestions] = useState([]);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const toggleAccordion = () => {
      setAccordionOpened((prevState) => !prevState);
    };

    function filterQuestionsByMonth(targetMonth, questions) {
      // Ensure targetMonth is a valid number between 1 and 12
      if (targetMonth < 1 || targetMonth > 12) {
        throw new Error("Invalid target month. It should be between 1 and 12.");
      }

      // Filter questions by target month
      return questions.filter((question) => {
        const createdDate = new Date(question.date_created);
        const questionMonth = createdDate.getMonth() + 1;
        const answered = question.answered;
        return targetMonth == questionMonth && answered;
      });
    }

    useEffect(() => {
      let theMonthsQuestions = filterQuestionsByMonth(month, data);

      setMonthsQuestions(theMonthsQuestions);
    }, []);

    return (
      <AccordionWrap className="accordion-item">
        <div className="accordion-header" onClick={(e) => toggleAccordion()}>
          <h3>{`${months[month - 1]} ${year}`}</h3>
          <div>
            <div className="number-bubble">{monthsQuestions.length}</div>
            <img
              className={`arrow ${accordionOpened ? "opened" : "closed"}`}
              src={accordionArrow}
              alt="arrow"
            />
          </div>
        </div>
        <div
          className={`accordion-content ${
            accordionOpened ? "opened" : "closed"
          }`}
        >
          {monthsQuestions &&
            monthsQuestions.map((question) => {
              return (
                <Question
                  answeredMode={true}
                  adminMode={adminMode}
                  key={question.id}
                  questionInfo={question}
                  theme={theme}
                  onVoteChange={handleVoteChange}
                  onApproveComment={updateCommentData}
                />
              );
            })}
        </div>
      </AccordionWrap>
    );
  };

  // TODO
  // Make calls server side

  return (
    <PageWrapper>
      <FullWidthContainer>
        {isAdmin && (
          <p className="admin-btn" onClick={toggleAdmin}>
            Admin Mode:{" "}
            <span className={`status ${adminMode ? "on" : "off"}`}>
              {" "}
              {adminMode ? "On" : "Off"}
            </span>
          </p>
        )}
        <Hero>
          <div>
            <h1>Big {isTom ? "Ass" : "Ask"} Portal</h1>
            <p>
              Welcome to the Big Ask Portal! Use this space to submit questions
              directly to our leadership team. While Q&A sessions are typically
              held during Town Halls, team members may submit questions and
              upvote/downvote at any time.
            </p>
            <p>Make your voice heard below.</p>
          </div>

          <SubmitWrapper>
            <h2>Submit a Question</h2>
            <textarea ref={suggestionRef} placeholder="Your question here…" />
            <p>
              <i>
                Your name will be automatically populated alongside your
                question.
              </i>
            </p>
            <SubmitButton onClick={handleSubmit}>Submit Question</SubmitButton>
            {message && <p className={message.type}>{message.message}</p>}
          </SubmitWrapper>
        </Hero>
        <Questions>
          <div
            className="pending-header"
            onClick={() => togglePendingCollapse()}
          >
            <h2>Pending Questions</h2>
            <div>
              <div className="number-bubble">{pendingQuestions.length}</div>
              <img
                className={`arrow ${closePending ? "closed" : "open"}`}
                src={accordionArrow}
                alt="arrow"
              />
            </div>
          </div>
          <div
            className={`pending-questions ${closePending ? "closed" : "open"}`}
          >
            {pendingQuestions.length > 0 ? (
              pendingQuestions.map((question) => (
                <Question
                  adminMode={adminMode}
                  key={question.id}
                  questionInfo={question}
                  theme={theme}
                  onVoteChange={handleVoteChange}
                  onApproveComment={updateCommentData}
                />
              ))
            ) : (
              <p className="no-questions">
                We've cleared the board! Submit a new question.
              </p>
            )}
          </div>
          <div id="answered-questions">
            <h2>Answered Questions</h2>
            <QuestionAccordion month={7} year={2024} data={questions} />
            <QuestionAccordion month={6} year={2024} data={questions} />
          </div>
        </Questions>
      </FullWidthContainer>
    </PageWrapper>
  );
}

export default withTheme(AskPage);
